import intlTelInput from 'intl-tel-input';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';

console.log('loading');
var input = document.querySelector(".intl-phone");
if (input) {
    console.log("found intl phone");
    if (window.default_country_code) {
        window.iti = intlTelInput(input, {
            separateDialCode: true,
            initialCountry: window.default_country_code,
        });
    } else {
        window.iti = intlTelInput(input, {
            separateDialCode: true,
            initialCountry: (window.user_country_code ?? "us"),
        });
    }
}

export function formatPhone(phone) {
    var data = window.iti.getSelectedCountryData();
    var code = data.iso2.toUpperCase();
    console.log(code);
    if (code && phone) {
        return new AsYouType(code).input(phone);
    }
}

jQuery(document).ready(function () {
    var input = document.querySelector(".intl-phone");
    if (input) {
        var value = jQuery('.intl-phone').val();
        var phone = formatPhone(value);
        jQuery('.intl-phone').val(phone);
    }
});

jQuery('body').on('keypress', '.intl-phone', function (e) {
    var value = jQuery(this).val();
    var phone = formatPhone(value);
    jQuery(this).val(phone);
});

export function validatePhone() {
    var data = window.iti.getSelectedCountryData();
    var code = data.iso2.toUpperCase();
    var phone = parsePhoneNumberFromString(jQuery('.intl-phone').val(), code);
    if (phone && phone.isValid()) {
        return true;
    } else {
        return false;
    }
}

window.validatePhone = function () {
    return validatePhone();
}

window.getFullNumber = function () {
    var data = window.iti.getSelectedCountryData();
    var code = data.iso2.toUpperCase();
    var phone = parsePhoneNumberFromString(jQuery('.intl-phone').val(), code);
    if (phone) {
        return phone.format("E.164");
    } else {
        return null;
    }
}

window.getSelectedCode = function () {
    var data = window.iti.getSelectedCountryData();
    var code = data.dialCode;
    return "+" + code;
}